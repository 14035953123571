<template>
	<div>
		<div class="menuList">
			<div v-for="item in vipList" @click="clickMenu(item)"
				:class="['menu_i', isMenu == item.id ? 'actMenu' : '']" :key="item.id">
				<span v-if="item.id == 2"> {{ item.text }}({{ item.num }})</span>
				<span v-else> {{ item.text }}</span>
			</div>
		</div>
		<div class="xian"></div>
		<!-- 公告站内信 -->
		<div v-show="isMenu == 1 || isMenu == 2">
			<!--      <div class="noticRadio">-->
			<!--        <el-radio-group v-model="noticRadio" :key="radioKey">-->
			<!--          <el-radio @click.native.prevent="onRadioChange(1)" :label="1"-->
			<!--            >只查看7天内的公告</el-radio-->
			<!--          >-->
			<!--        </el-radio-group>-->
			<!--      </div>-->
			<el-table :data="tableData" :header-cell-style="{ background: '#EEEEEE' }"
				:row-class-name="tableRowClassName" :show-header="false" @current-change="handleCurrentChange"
				style="cursor: pointer">
				<el-table-column width="200px" align="center">
					<template slot-scope="scope" v-if="scope.row.top == 0">
						<div class="zdbtn">{{$t('N_I_245')}}</div>
					</template>
				</el-table-column>
				<el-table-column width="400px" align="left" prop="title" label=""></el-table-column>
				<el-table-column align="center" prop="creationTime" label=""></el-table-column>
			</el-table>
		</div>
		<!-- 意见反馈列表 -->

		<div v-show="isMenu == 3">
			<div class="listbox" v-for="(item, index) in feedBackList" :key="index">
				<el-avatar :size="54" :src="item.headPath"></el-avatar>
				<div class="righreptbox">
					<div class="wailist">
						<div class="listflex">
							<div class="ideadetail">{{ item.idea }}</div>
							<div v-if="item.type == 1" class="ideatype thetype1">
								{{ item.typeVal }}
							</div>
							<div v-if="item.type == 2" class="ideatype thetype2">
								{{ item.typeVal }}
							</div>
							<div v-if="item.type == 3" class="ideatype thetype3">
								{{ item.typeVal }}
							</div>
							<div v-if="item.type == 4" class="ideatype thetype4">
								{{ item.typeVal }}
							</div>
						</div>

						<div>{{ item.creationTime }}</div>
					</div>
					<div class="replay" v-if="item.feedbackReplyList.length > 0">
						<div v-for="aitem in item.feedbackReplyList" :key="aitem.pkId">
							<div>系统回复：</div>
							<div class="themax" style="margin-left: 80px" v-html="aitem.replyContent"></div>
							<div class="time">{{ aitem.creationTime }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as sid from "@/api/sidebaruserinfo.js";
	export default {
		data() {
			return {
				vipList: [{
						text: this.$t('w_0308'),
						id: 1,
					},
					{
						text: this.$t('ENU_APPROVE_B_522'),
						id: 2,
					},
					{
						text: this.$t('MN_F_T_84'),
						id: 3,
					},
				],
				isMenu: 1,
				tableData: [],
				feedBackList: [],
				noticRadio: "",
				radioKey: false,
			};
		},
		watch: {
			$route(n, o) {
				this.isMenu = n.query.index;
				this.getList();
			},
		},
		created() {},
		mounted() {
			this.isMenu = this.$route.query.index;
			this.getList();
		},
		methods: {
			//公告、站内信点击单行跳转到详情
			handleCurrentChange(val) {
				this.$router.push({
					path: "/noticeDetail",
					query: {
						pkId: val.pkId,
						functionType: this.isMenu
					},
				});
			},
			onRadioChange(val) {
				this.noticRadio = this.noticRadio == val ? "" : val;
				this.radioKey = !this.radioKey;
				this.getList();
			},
			clickMenu(item) {
				this.isMenu = item.id;
				this.getList();
			},
			getList() {
				if (this.isMenu == 1) {
					sid
						.getNoticeList({
							functionType: 1,
							isShowDay: this.noticRadio,
							pageSize: 50
						})
						.then((res) => {
							this.tableData = res.rows;
						});
				} else if (this.isMenu == 2) {
					sid
						.getNoticeList({
							functionType: 2,
							isShowDay: this.noticRadio
						})
						.then((res) => {
							this.tableData = res.rows;
						});
				} else if (this.isMenu == 3) {
					sid.getFeedbackList().then((res) => {
						this.feedBackList = res.rows;
					});
				}
				sid.getNoticeCount().then((res) => {
					this.vipList.forEach((ele) => {
						if (ele.id == 1) {
							ele.num = res.data.noticeNum;
						} else if (ele.id == 2) {
							ele.num = res.data.stationNum;
						} else if (ele.id == 3) {
							ele.num = res.data.feedbackNum;
						}
					});
					this.$forceUpdate();
				});
			},
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (rowIndex % 2 == 1) {
					return "warning-row";
				} else if (rowIndex % 2 == 0) {
					return "success-row";
				}
				return "";
			},
		},
	};
</script>

<style lang="scss" scoped>
	::v-deep .themax {
		img {
			max-width: 100%;
			height: auto;
		}
	}

	::v-deep .success-row {
		background: #f3f3f3;
	}

	.zdbtn {
		border: 1px solid #ff5d5d;
		color: #ff5d5d;
		width: 80px;
		margin: 0 auto;
		border-radius: 5px;
	}

	.menuList {
		display: flex;
		margin-top: 20px;
		padding: 0 24px;
		box-sizing: border-box;

		.menu_i {
			margin-right: 70px;
			line-height: 50px;
			cursor: pointer;
		}

		.actMenu {
			border-bottom: 5px solid #b42b2a;
		}
	}

	.xian {
		height: 5px;
		background: rgba(0, 0, 0, 0.05);
		margin-top: -5px;
	}

	.noticRadio {
		// height: 78px;
		display: flex;
		align-items: center;
		padding: 20px 40px;
		font-size: 20px;
	}

	.listbox {
		padding: 20px 40px;
		border-bottom: 2px solid rgba(0, 0, 0, 0.05);
		display: flex;

		.righreptbox {
			width: 100%;
			padding: 0 20px;

			.wailist {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.listflex {
					display: flex;
					align-items: center;

					.ideadetail {
						margin-right: 10px;
						font-size: 14px;
						font-family: PingFang SC-Regular, PingFang SC;
						font-weight: 400;
						color: #666666;
					}

					.ideatype {
						border-radius: 2px 2px 2px 2px;
						font-size: 10px;
						font-family: PingFang SC-Regular, PingFang SC;
						font-weight: 400;
						//   height: 20px;

						margin-right: 10px;
						line-height: 12px;
						padding: 5px;
					}

					.thetype1 {
						color: #1aa90e;
						border: 1px solid #1aa90e;
					}

					.thetype2 {
						color: #d61820;
						border: 1px solid #d61820;
					}

					.thetype3 {
						color: #fda521;
						border: 1px solid #fda521;
					}

					.thetype4 {
						color: #1b8ddf;
						border: 1px solid #1b8ddf;
					}
				}
			}

			.replay {
				padding: 10px;
				background: #ebebeb;
				margin-top: 15px;
				font-size: 14px;

				.time {
					color: #666666;
					text-align: right;
				}
			}
		}
	}
</style>